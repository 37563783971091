import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404" />
    <div className="container" style={{marginBottom: '30px'}}>
      <h1>404 : Fausse route !</h1>
      <p>Cette page ne semble pas exister sur ce site ! Vérifiez votre URL ?</p>
    </div>
  </Layout>
)

export default NotFoundPage
